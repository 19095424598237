/** TAILWIND */
@tailwind base;
@tailwind components;
@tailwind utilities;

/** FONT */

@font-face {
  font-family: Spoqa Han Sans Neo;
  font-weight: 100;
  src: url("/assets/fonts/SpoqaHanSansNeo-Thin.eot") format('eot'),
       url("/assets/fonts/SpoqaHanSansNeo-Thin.otf") format('otf'),
       url("/assets/fonts/SpoqaHanSansNeo-Thin.woff") format('woff'),
       url("/assets/fonts/SpoqaHanSansNeo-Thin.woff2") format('woff2');
}

@font-face {
  font-family: Spoqa Han Sans Neo;
  font-weight: 300;
  src: url("/assets/fonts/SpoqaHanSansNeo-Light.eot") format('eot'),
       url("/assets/fonts/SpoqaHanSansNeo-Light.otf") format('otf'),
       url("/assets/fonts/SpoqaHanSansNeo-Light.woff") format('woff'),
       url("/assets/fonts/SpoqaHanSansNeo-Light.woff2") format('woff2');
}

@font-face {
  font-family: Spoqa Han Sans Neo;
  font-weight: 400;
  src: url("/assets/fonts/SpoqaHanSansNeo-Regular.eot") format('eot'),
       url("/assets/fonts/SpoqaHanSansNeo-Regular.otf") format('otf'),
       url("/assets/fonts/SpoqaHanSansNeo-Regular.woff") format('woff'),
       url("/assets/fonts/SpoqaHanSansNeo-Regular.woff2") format('woff2');
}

@font-face {
  font-family: Spoqa Han Sans Neo;
  font-weight: 500;
  src: url("/assets/fonts/SpoqaHanSansNeo-Medium.eot") format('eot'),
       url("/assets/fonts/SpoqaHanSansNeo-Medium.otf") format('otf'),
       url("/assets/fonts/SpoqaHanSansNeo-Medium.woff") format('woff'),
       url("/assets/fonts/SpoqaHanSansNeo-Medium.woff2") format('woff2');
}

@font-face {
  font-family: Spoqa Han Sans Neo;
  font-weight: 700;
  src: url("/assets/fonts/SpoqaHanSansNeo-Bold.eot") format('eot'),
       url("/assets/fonts/SpoqaHanSansNeo-Bold.otf") format('otf'),
       url("/assets/fonts/SpoqaHanSansNeo-Bold.woff") format('woff'),
       url("/assets/fonts/SpoqaHanSansNeo-Bold.woff2") format('woff2');
}

@font-face {
  font-family: 'SFHambakSnow';
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/SFHambakSnow.ttf') format('truetype');
}

body {
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: border-box;
  background: #111;
  position: static !important;
  font-size: 14px;
  word-spacing: 1px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overscroll-behavior: contain;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-weight: 400;
}

a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1 {
  font-weight: 700;
  line-height: 0.25rem;
}

h4 {
  font-weight: 500;
  line-height: 2rem;
}

.preventScroll {
  overflow: hidden;
  height: 100%;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

* ::-webkit-scrollbar {
  display: none;
}

.webgl-element {
  aspect-ratio: auto 2268 / 1296;
}

button {
  border: none;
  cursor: pointer;
  background:transparent;
}

*, * ::before, * ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* :focus {
  outline:none;
}