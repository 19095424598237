.scene {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.canvas {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-width: 1024px;
  min-height: 720px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  overflow: hidden;
}

canvas {
  aspect-ratio: 16/9;
  object-fit: cover;
  z-index: inherit;
}

.smFont {
  font-size: 1.8vh;
}

.video {
  position: relative;
  z-index: 999;
}

.btn-play {
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 22vw;
  height: 40vh;
  object-fit: contain;
  z-index: 5;
}

iframe.video {
  position: absolute;
  top: 50%;
  left: 50%;
}

.introWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro {
  background-color: #ffffff;
  float: left;
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 1;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  -webkit-animation: fadeIn;
  animation: fadeIn;
  opacity: 1;
}

.fadeOut {
  -webkit-animation: fadeOut;
  animation: fadeOut;
  pointer-events: none;
  opacity: 0;
}

.superFast {
  -duration: 0.1s;
  animation-duration: 0.1s;
}

.fastest {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.fast {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.slow {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

.up {
  transform: translate3d(0, 50%, 0);
}

.down {
  transform: translate3d(0, -50%, 0);
}

.transitionFast {
  transition: all 1000ms ease 0s;
}

.transitionFastest {
  transition: all 500ms ease 0s;
}

.bgmBtn {
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border: solid 2px #ffffff55;
  border-radius: 70%;
  float: left;
  position: absolute;
  top: 50px;
  right: 50px;
  display: block;
  height: 50px;
  width: 50px;
  z-index: 1;
}

.cookie-box {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  bottom: 6vh;
  max-width: 1280px;
  width: 100vw;
  z-index: 999 !important;
  padding: 20px 35px 20px 35px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(204, 204, 204, 0.5);
  font-size: 18px;
}

.cookie-box-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(51 65 85);
  color: #fff;
  width: 120px;
  height: 40px;
  transition: all 500ms ease 0s;
}

.cookie-box-btn:hover {
  background-color: rgba(51, 65, 85, 0.8);
}

.table-cookies td {
  border-collapse: collapse;
  border: 1px solid #fff;
  min-height: 1em;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 1.7;
}

.table-cookies p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
}

.content-cookies {
  padding: 0 0 10vh;
}

.content-cookies a {
  color: rgb(129 140 248);
}

.fixedBg {
  top: 0;
  position: fixed;
  background-image: url(../../public/bg-section04.webp);
}

.fullscreen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #fff;
}

.loadState {
  position: absolute;
  bottom: 20%;
  font-size: 30px;
  font-weight: 100;
  text-align: center;
  line-height: 60px;
  color: #ccc;
  z-index: 2;
  pointer-events: none;
}

.canvasLinkBtn {
  color: #fff;
  background-color: #ffffff15;
  position: absolute;
  display: block;
  height: 500px;
  width: 500px;
  border-radius: 100%;
  z-index: 5;
  transition: transform 1s;
}

.likeVideo {
  background-size: contain;
  background-position: center;
  display: block;
  z-index: 2;
  background-repeat: no-repeat;
}

.pointerActive {
  cursor: pointer;
  pointer-events: ease;
}

.home {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* height: 100vh;
  height: calc(var(--h, 1vh) * 100); */
}

.fullPage {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  height: 100vh;
  height: calc(var(--h, 1vh) * 100);
}

@media (prefers-reduced-motion: reduce) {
  .fullPage {
    scroll-behavior: smooth;
  }
}

.blurGlass {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.blurBox {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}

.borderWhite {
  border-color: #fff;
  border-radius: 0.2rem;
  border-width: 1px;
}

.borderBottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.borderBottomBold {
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
}

.navBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.news-tab {
  position: relative;
  text-align: center;
  font-size: 1.8vh;
}

.news-tab::after {
  position: absolute;
  left: 0;
  bottom: -3px;
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  background: #fff;
}

.news-content {
  overflow-y: scroll;
}

.news-list {
  width: 100%;
}

.news-list a:hover {
  background-color: #ffffff15;
  transition: all 500ms ease 0s;
}

.news-title {
  width: 70%;
  font-size: 1.6vh;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1vh;
}

.news-date {
  width: 30%;
  font-size: 1.2vh;
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 1vh;
}

.news-more {
  position: relative;
  display: flex;
  align-items: center;
}

.news-more a:hover {
  background: #ffffff15;
  border-radius: 0.2rem;
  transition: all 500ms ease 0s;
}

.section-content {
  width: 100%;
  height: calc((var(--h, 1vh) * 100) - 8vh);
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.section-content h3 {
  position: relative;
  font-size: 4vh;
  color: white;
}

.section-content h3::before {
  left: 0;
  transform: translate(calc(-100% - 3vh), -50%);
}

.section-content h3::after {
  right: 0;
  transform: translate(calc(100% + 3vh), -50%) rotate(180deg);
}

.section-content h3::before,
.section-content h3::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 30vw;
  height: 1vh;
  background: url(../../public/section-line.webp) no-repeat right center/contain;
}

.textShadow {
  text-shadow: 0 2px 3px #000;
}

.playBtn {
  transition: all 500ms ease 0s;
  width: 20vh;
}

.playBtn:hover {
  scale: 105%;
}

.youtube {
  background-color: #aaa;
  -webkit-mask: url(../../public/youtube.svg) no-repeat center;
  mask: url(../../public/youtube.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.share {
  background-color: #aaa;
  -webkit-mask: url(../../public/share.svg) no-repeat center;
  mask: url(../../public/share.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.external {
  background-color: #aaa;
  -webkit-mask: url(../../public/external.svg) no-repeat center;
  mask: url(../../public/external.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.link {
  background-color: #aaa;
  -webkit-mask: url(../../public/link.svg) no-repeat center;
  mask: url(../../public/link.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.twitter {
  background-color: #aaa;
  -webkit-mask: url(../../public/twitter.svg) no-repeat center;
  mask: url(../../public/twitter.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.hamburger {
  background-color: #aaa;
  -webkit-mask: url(../../public/hamburger.svg) no-repeat center;
  mask: url(../../public/hamburger.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.navUp {
  background-color: #aaa;
  -webkit-mask: url(../../public/up.svg) no-repeat center;
  mask: url(../../public/up.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.facebook {
  background-color: #aaa;
  -webkit-mask: url(../../public/facebook.svg) no-repeat center;
  mask: url(../../public/facebook.svg) no-repeat center;
  transition: all 500ms ease 0s;
}

.entryBtn {
  position: relative;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border: 1px solid #ffffff20;
  border-radius: 9999px;
  font-size: 3.2vh;
  font-weight: 700;
  text-align: center;
  color: #aaa;
  transition: all 500ms ease 0s;
}

.entryBtn:hover {
  scale: 105%;
  color: #fff;
}

.youtube:hover,
.share:hover,
.link:hover,
.twitter:hover,
.facebook:hover,
.hamburger:hover,
.up:hover {
  background-color: #fff;
}

.entryBtn:hover .external {
  background-color: #fff;
}

.entryBtn::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 9999px;
  content: ' ';
  background: linear-gradient(
    120deg,
    #6da5f9 0%,
    #6da4f9 19%,
    #6f76f7 53%,
    #7066f7 65%,
    #7e68f7 77%,
    #a670fa 89%,
    #e47cfe 100%
  );
  transition: all 500ms ease 0s;
  opacity: 0;
}

.entryBtn:hover::before {
  opacity: 1;
}

/* Navigation */
nav ul {
  list-style: none;
  text-align: center;
}

nav ul li {
  display: inline-block;
}

nav ul li button {
  display: block;
  text-decoration: none;
  color: #aaa;
  text-transform: uppercase;
  margin: 0 10px;
}

nav button,
nav ul li button,
nav ul li button:after,
nav ul li button:before,
nav ul li .navAncher,
nav ul li .navAncher:after,
nav ul li .navAncher:before {
  color: #aaa;
  font-size: 2vh;
  transition: all 500ms ease 0s;
}

nav button:hover,
nav ul li button:hover,
nav ul li button.active,
nav ul li .navAncher:hover,
nav ul li .navAncher.active {
  color: #fff;
}

nav a:hover .external {
  background-color: #fff;
}

nav.stroke ul li button,
nav.stroke ul li .navAncher {
  position: relative;
}

nav.stroke ul li button:after,
nav.stroke ul li .navAncher:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}

nav.stroke ul li button:hover:after,
nav.stroke ul li button.active:after,
nav.stroke ul li .navAncher:hover:after,
nav.stroke ul li .navAncher.active:after {
  width: 100%;
  background: #fff;
}

@media (hover: none) and (pointer: coarse) {
  nav ul li .navAncher:hover {
    color: #aaa;
  }

  nav ul li .navAncher.active {
    color: #fff;
  }

  nav.stroke ul li .navAncher:hover:after {
    background: transparent;
  }

  nav.stroke ul li .navAncher.active:after {
    width: 100%;
    background: #fff;
  }
}

.slideDown {
  width: 100vw;
  position: absolute;
}

.share-box {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border: 1px solid #ffffff20;
  border-radius: 10px;
  transition: all 200ms ease 0s;
}

.division {
  border-bottom: 1px solid #ffffff20;
}

/* Image Slider */
.illustSlider.swiper,
.charSlider.swiper {
  width: 100vw;
  height: calc(var(--h, 1vh) * 50);
}

.noticeSlider.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* Center slide text verticease-outy */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.charSlider .swiper-slide img {
  height: 100%;
}

.illustSlider .swiper-slide img {
  width: 100%;
  height: 100%;
}

.noticeSlider .swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper .swiper-button-next {
  background-color: #fff;
  -webkit-mask: url(../../public/scroll-next.svg) no-repeat center;
  mask: url(../../public/scroll-next.svg) no-repeat center;
  transition: all 500ms ease 0s;
  background-size: 100% auto;
  background-position: center;
  width: 10vh;
  height: 10vh;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper .swiper-button-prev {
  background-color: #fff;
  -webkit-mask: url(../../public/scroll-prev.svg) no-repeat center;
  mask: url(../../public/scroll-prev.svg) no-repeat center;
  transition: all 500ms ease 0s;
  background-size: 100% auto;
  background-position: center;
  width: 10vh;
  height: 10vh;
}

.swiper .swiper-pagination {
  width: 19vh !important;
}

.swiper .swiper-pagination-bullet {
  background-color: #fff;
  -webkit-mask: url(../../public/pagination.svg) no-repeat center;
  mask: url(../../public/pagination.svg) no-repeat center;
  transition: all 500ms ease 0s;
  width: 3vh;
  height: 3vh;
}

:root {
  --char-width: 18.8vh;
  --char-height: 45vh;
  --char-transition-duration: 800ms;
  --char-transition-easing: ease;
}

@media screen and (min-width: 740px) {
  .table-cookies td {
    padding-left: 0.12rem;
    padding-right: 0.12rem;
  }
}

@media screen and (min-width: 1440px) {
  :root {
    --char-width: 188px;
    --char-height: 450px;
  }
}

@media screen and (min-width: 1600px) {
  .canvas,
  .fullscreen {
    min-height: 900px;
  }
}

@media screen and (min-width: 2550px) {
  :root {
    --char-width: 292px;
    --char-height: 700px;
  }
  .canvas,
  .fullscreen {
    min-height: 1280px;
  }
}

.Toastify__toast-theme--dark {
  background: rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border: 1px solid #ffffff20;
}

.Toastify__toast-body {
  text-align: center;
}

#header {
  position: relative;
  float: left;
  left: 1px;
  z-index: 100;
  width: 273px;
  height: 100%;
  padding: 50px 25px;
  background-color: #fff;
  border-right: 1px solid #b1b1b1;
  box-sizing: border-box;
}

#contents {
  position: relative;
  float: left;
  width: 1027px;
  min-height: 1280px;
  padding: 70px 0 160px;
  border-left: 1px solid #b1b1b1;
  box-sizing: border-box;
}

.top-util {
  position: absolute;
  left: 100%;
  top: 0;
  width: 1026px;
  height: 70px;
  border-bottom: 1px solid #b1b1b1;
}

.top-util .btn-login {
  min-width: 82px;
  margin: 18px 30px 18px 24px;
  font-size: 14px;
  border-radius: 96px;
  border: 1px solid #343636;
  color: #343636;
  line-height: 20px;
  height: 34px;
  padding: 0 15px;
}

.top-util .btn-login:hover {
  background-color: #343636;
  color: #fff;
}
